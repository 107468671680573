import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import All from '../../../components/EventsActivities/All'
import dictStore from '../../../store/dict'
import { observer } from 'mobx-react-lite';
import HeadNavigation from '../../../store/HeadNavigation';
import { PlusOutlined } from '@ant-design/icons';
import detailsStore from '../../../store/Details'
import userStore from '../../../store/user'

const Home = observer(() => {
    const isTokenAvailable = userStore.state.token;
    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState<number | null>(null);
    const [dictDataList, setDictDataList] = useState<any[]>([
        { dictCode: null, dictLabel: 'All' },
        { dictCode: 'myCalendar', dictLabel: 'My calendar' },
    ]);
    useEffect(() => {
        if (dictStore.state.activity_category) {
            setDictDataList(prevList => [
                ...prevList.slice(0, 2),
                ...dictStore.state.activity_category
            ]);
        }
    }, [dictStore.state.activity_category]); 

    const navigateClick = (val: number) => {
        setActiveNav(val);
    };
    //跳转到新增页面
    const navigateHandle = () => {
        navigate(`/eventsActivities/addOrEdit?type=add`);
    }
    useEffect(() => {
        HeadNavigation.setItem({ backTo: '/home', goName: 'Home', currentName:'Events & Activities' });
    }, []);
    return (
        <div className="w-full h-full flex">
            <div style={{width: detailsStore.open ? '0' : '77px'}} className="h-full flex flex-col justify-between overflow-auto border-r border-[#D2D2D2] py-4 space-y-3 min-3 min-26 min-15">
                <div>
                    {dictDataList.map((item, index) => (
                        <div key={index}
                            className={`w-full h-16 border-r-4 hover:bg-[#F6F8F8] cursor-pointer flex items-center pl-16 space-x-2 min-5
                                ${activeNav === item.dictCode ? 'border-[#275576] bg-[#F6F8F8] text-[#232323]' : 'border-white text-[#707070]'}`}
                            onClick={() => navigateClick(item.dictCode)}>
                            
                            {
                                index > 7 ? (
                                    <img src={require(`../../../assets/images/eventsActivities/8.svg`)} alt={item.index} />
                                ) : (
                                    <img src={require(`../../../assets/images/eventsActivities/${index + 1}.svg`)} alt={item.index} />
                                )
                            }
                            <span className="text-xl font-normal min-4">{item.dictLabel}</span>
                        </div>
                    ))}
                </div>
                <div className='w-full pb-4 px-6 min-4'>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='w-full h-11 flex items-center justify-center space-x-2'
                        onClick={navigateHandle}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send' />
                        <span className=' text-base font-medium text-white '> Submit new content</span>
                    </Button>
                </div>
                {isTokenAvailable ? <div className='min-13'>
                    <Button color="default" onClick={navigateHandle} icon={<PlusOutlined />} style={{marginBottom: '10px'}} />
                </div> : ''}
            </div>
            <div className="h-full flex flex-col justify-between overflow-auto border-r border-[#D2D2D2] py-4 space-y-3 min-3 min-26 min-4">
                <div>
                    {dictDataList.map((item, index) => (
                        <div key={index}
                            className={`w-full h-16 border-r-4 hover:bg-[#F6F8F8] cursor-pointer flex items-center pl-16 space-x-2 min-5
                                ${activeNav === item.dictCode ? 'border-[#275576] bg-[#F6F8F8] text-[#232323]' : 'border-white text-[#707070]'}`}
                            onClick={() => navigateClick(item.dictCode)}>
                            
                            {
                                index > 7 ? (
                                    <img src={require(`../../../assets/images/eventsActivities/8.svg`)} alt={item.index} />
                                ) : (
                                    <img src={require(`../../../assets/images/eventsActivities/${index + 1}.svg`)} alt={item.index} />
                                )
                            }
                            <span className="text-xl font-normal min-4">{item.dictLabel}</span>
                        </div>
                    ))}
                </div>
                {isTokenAvailable ? <div className='w-full pb-4 px-6 min-4'>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='w-full h-11 flex items-center justify-center space-x-2'
                        onClick={navigateHandle}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send' />
                        <span className=' text-base font-medium text-white '> Submit new content</span>
                    </Button>
                </div> : ''}
                {isTokenAvailable ? <div className='min-13'>
                    <Button color="default" onClick={navigateHandle} icon={<PlusOutlined />} style={{marginBottom: '10px'}} />
                </div> : ''}
            </div>
           {/*  <div className="h-full flex flex-col justify-between overflow-auto border-r border-[#D2D2D2] py-4 space-y-3 min-3 min-26 min-4">
                <div>
                    {dictDataList.map((item, index) => (
                        <div key={index}
                            className={`w-full h-16 border-r-4 hover:bg-[#F6F8F8] cursor-pointer flex items-center pl-16 space-x-2 min-5
                                ${activeNav === item.dictCode ? 'border-[#275576] bg-[#F6F8F8] text-[#232323]' : 'border-white text-[#707070]'}`}
                            onClick={() => navigateClick(item.dictCode)}>
                            
                            {
                                index > 7 ? (
                                    <img src={require(`../../../assets/images/eventsActivities/8.svg`)} alt={item.index} />
                                ) : (
                                    <img src={require(`../../../assets/images/eventsActivities/${index + 1}.svg`)} alt={item.index} />
                                )
                            }
                            <span className="text-xl font-normal min-4">{item.dictLabel}</span>
                        </div>
                    ))}
                </div>
                <div className='w-full pb-4 px-6 min-4'>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='w-full h-11 flex items-center justify-center space-x-2'
                        onClick={navigateHandle}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send' />
                        <span className=' text-base font-medium text-white '> Submit new content333</span>
                    </Button>
                </div>
                <div className='min-13'>
                    <Button color="default" onClick={navigateHandle} icon={<PlusOutlined />} style={{marginBottom: '10px'}} />
                </div>
            </div> */}
            <div className="flex-1 h-full mt-4 ml-7 pb-5 mr-16 overflow-auto min-6">
                <All category={activeNav} />
            </div>
        </div>
    );
});

export default Home;
