import { Input, Button, Form, Select, Radio, message } from 'antd';
import type { FormProps } from 'antd';
import { useState, useEffect,useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UploadFiles from '../../../components/Public/UploadFiles';
import UploadType from '../../../store/UploadType'
import indexStore from '../../../store/index'
import { addVideoImgInfo, updateVideoImgInfo } from '../../../api/healthOnline'
import dictStore from '../../../store/dict'
import WangEditorComponent from '../../../components/Public/WangEditorView';
import HeadNavigation from '../../../store/HeadNavigation';
import detailsStore from '../../../store/Details'
type FieldType = {
    title: string,
    subtitle: string,
    category: number,
    type: number,
    status: number,
    commentStatus: number,
    bannerPath: string,
    filePath: string,
    description: string,
    content: string,
    contentTags: any
};

// 上传文件
const Add = () => {
    // 定义
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    const [initialValues] = useState<any>(type === 'add' ? {} : detailsStore.Details); //详情数据 初始表单
    const [bannerPath, setBannerPath] = useState<string>(initialValues?.bannerPath || ''); //封面
    const [filePath, setFilePath] = useState<string>(initialValues?.filePath || ''); //视频/音频地址
    const description = useRef<any>('');//富文本编辑器详情
    const [moduleType] = useState<number>(indexStore.menusState.moduleType); //区别管理员/用户模块
    const [pathKey] = useState<string>(moduleType === 1 ? 'health' : 'online'); //模块分类
    const [buildType] = useState<number>(searchParams.get('buildType') || detailsStore.Details.type); //添加作品的类型
    const [buildTypeLabel, setBuildTypeLabel] = useState<string>(''); //添加作品的类型的名称
    const [isDraft, setIsDraft] = useState(false); // 用于标记是否保存为草稿
    // 加载状态
    const [loadings, setLoadings] = useState<boolean>(false);
    const [form] = Form.useForm();
    // 保存数据
    const handleSignupClick: FormProps<FieldType>['onFinish'] = async (values) => {
        setLoadings(true)
        const param: any = {
            ...values,
            bannerPath,
            filePath,
            type: buildType,
            moduleType,
            contentTags: values.contentTags.join(',')

        }
        // 文章类型
        if (Number(buildType) === 102) {
            param.description = description.current
        }
        param.status = isDraft ? 1 : 4
        try {
            const res = type === 'add'
                ? await addVideoImgInfo(param)
                : await updateVideoImgInfo({ ...param, id: searchParams.get('id') });

            if (res.data.code === 200) {
                message.success(res.data.msg)
                navigate('/healthOnline/home');
            }
            setLoadings(false)
        } catch (error) {
            setLoadings(false)
            console.error("Error submitting form:", error);
        }
    }
    // 文件上传成功并获取到 signedUrl 图片地址 
    const handleFileUploadSuccess = (url: string) => {
        setBannerPath(url)
    };
    // 文件上传成功并获取到 signedUrl MP4视频地址地址 
    const handleFileUploadSuccessMp4 = (url: string) => {
        setFilePath(url)
    };
    // 富文本编辑框的内容
    const handleEditorChange = (val:any) => {
        description.current=val
    };
    // 预览
    const handlePreview = async () => {
        try {
            const values = await form.validateFields();
            const param: any = {
                ...values,
                bannerPath,
                filePath,
                type: Number(buildType),
                moduleType,
                contentTags: values?.contentTags.join(',')

            };
            // 文章类型
            if (Number(buildType) === 102) {
                param.description =  description.current
            }
            detailsStore.setDetails({ ...param })
            window.open('/healthOnline/details', '_blank');
        } catch (error) {
            console.dir(error)
        }
    }
    //获取 公告 文章类型数据label 
    const getCategoryType = (tagCode: number) => {
        const dictItem = dictStore.state.article_type.find((item: any) => item.dictCode === Number(tagCode)) || { dictLabel: '' };
        if (dictItem) {
            setBuildTypeLabel(dictItem.dictLabel)
        }
    }
    useEffect(() => {
        getCategoryType(buildType)
    }, [buildType]);
    useEffect(() => {
        const currentName = moduleType === 1 ? 'Health & wellness' : 'Online classes';
        HeadNavigation.setItem({ backTo: '/healthOnline/home', goName: currentName, currentName });
    }, []);
    return (
        <Form
            style={{ maxWidth: '100%' }}
            form={form}
            onFinish={handleSignupClick}
            layout="vertical"
            className="w-full h-full flex flex-col py-5 px-14 space-y-7  min-1-1"


        >
            <div className="flex items-center justify-between min-1-1">
                <div className="text-4xl font-medium text-[#232323] min-10">Submit new {UploadType.type} content</div>
                <div className=' flex items-center space-x-5 min-4'>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='h-11  px-4 space-x-2  flex items-center  '
                        onClick={() => navigate(-1)}
                    >
                        <img src={require('../../../assets/images/public/close.png')} alt='close' />
                        <span className='text-base font-medium text-[#275576] '> Cancel</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2'
                        htmlType="button"
                        onClick={handlePreview} // 点击时设置为草稿状态
                    >
                        <img src={require('../../../assets/images/public/eye.png')} alt='eye' />
                        <span className=' text-base font-medium text-[#275576] '> Preview</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: 'none', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2'
                        htmlType="submit"
                        onClick={() => setIsDraft(true)} // 点击时设置为草稿状态
                        loading={loadings}
                    >
                        <img src={require('../../../assets/images/public/save.png')} alt='save' />
                        <span className=' text-base font-medium text-[#275576] '> Save as draft</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2 '
                        htmlType="submit"
                        onClick={() => setIsDraft(false)}
                        loading={loadings}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send-02' />
                        <span className=' text-base font-medium text-white '> Submit new content</span>
                    </Button>

                </div>
            </div>
            <div className='w-full h-64 min-15 min-15-15 '>
                <UploadFiles onFileUploadSuccess={handleFileUploadSuccess} keyPrefix={pathKey}>
                    <div className='h-full flex flex-col items-center justify-center'>
                        <img src={require(`../../../assets/images/public/upload-01.png`)} alt='upload' />
                        <p className=" text-base font-normal text-[#275576]">Upload image</p>
                    </div>
                    {bannerPath && <img className='w-full h-full object-cover absolute top-0 left-0' src={bannerPath} alt='imgSrc' />}
                </UploadFiles>
            </div>
            <div className=' py-5 space-y-4 min-15'>
                <p className=' text-lg font-normal text-[#707070]'>Comments settings</p>
                <Form.Item<FieldType>
                    name="commentStatus"
                    initialValue={initialValues.commentStatus || 1}
                >
                    <Radio.Group >
                        <div>
                        <Radio value={1} >Visible</Radio>
                        </div>
                        <div>
                        <Radio value={2}>Hidden</Radio>
                        </div>
                        <div>
                        <Radio value={3}>Locked</Radio>
                        </div>
                    </Radio.Group>
                </Form.Item>
            </div>
            <div className='border-b border-[#D2D2D2] pb-5 min-15 '>
                <p className=' text-xl font-normal text-[#232323]'>Main banner image</p>
                <div className=' space-y-2 text-lg font-normal text-[#707070]'>
                    <p>Please use JPG or PNG with non-transparent background.</p>
                    <p>Recommended dimensions of 520x240</p>
                </div>
            </div>
            <div className="w-full flex space-x-5 overflow-hidden min-1-1 min-28">
                <div className='flex-1 h-full border border-[#D2D2D2] rounded-md overflow-auto'>
                    <p className='w-full px-5 text-xl font-medium h-16 leading-[64px] bg-[#F6F8F8]'>Details</p>
                    <div className='p-5 space-y-7'>
                        <div className='w-full flex justify-between space-x-5 min-18'>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="title"
                                label="Title"
                                initialValue={initialValues.title}
                                rules={[
                                    { required: true, message: 'Please input Ex: Power of light.' },
                                ]}>
                                <Input placeholder="Ex: Power of light."
                                    style={{ height: '3.5rem', width: '100%' }} />
                            </Form.Item>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="subtitle"
                                label="subtitle"
                                initialValue={initialValues.subtitle}
                                rules={[
                                    { required: true, message: 'Please input Ex: Getting the right light for Improved health.' },
                                ]}>
                                <Input placeholder="EEx: Getting the right light for Improved health."
                                    style={{ height: '3.5rem', width: '100%' }} />
                            </Form.Item>
                        </div>
                        <div className='w-full flex justify-between space-x-5 min-18'>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="category"
                                label="Select categories"
                                initialValue={initialValues.category}
                                rules={[
                                    { required: true, message: 'Please Select' },
                                ]}>
                                <Select
                                    style={{ height: '3.5rem', width: '100%' }}
                                    placeholder="Select"
                                    dropdownStyle={{ zIndex: 10002 }}
                                >
                                    {dictStore.state.categoryData.map((item: any) => (
                                        <Select.Option label={item.dictLabel} value={item.dictCode} key={item.dictCode}>
                                            {item.dictLabel}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item<FieldType>
                                className='w-full h-14 min-17'
                                name="contentTags"
                                label="Tags"
                                initialValue={initialValues?.contentTags?.split(',').map((numStr: any) => parseInt(numStr.trim()))}
                                rules={[
                                    { required: true, message: 'Please Search and add tags ' },
                                ]}>
                                <Select
                                    mode="multiple"
                                    style={{ height: '3.5rem', width: '100%' }}
                                    placeholder="Select"
                                    dropdownStyle={{ zIndex: 10002 }}
                                >
                                    {dictStore.state.article_tags.map((item: any) => (
                                        <Select.Option label={item.dictLabel} value={item.dictCode} key={item.dictCode}>
                                            {item.dictLabel}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        {buildTypeLabel !== 'Article' &&
                            <Form.Item<FieldType>
                                name="description"
                                label="Summary"
                                initialValue={initialValues.description}
                                rules={[
                                    { required: true, message: 'Please input your Email address or phone number!' },
                                ]}
                                className='w-full'
                            >
                                <Input.TextArea rows={4} placeholder="Write here.." />
                            </Form.Item>
                        }
                        {(buildTypeLabel === 'Video' || buildTypeLabel === 'Audio') &&
                            <Form.Item<FieldType>
                                name="filePath"
                                initialValue={initialValues.filePath}
                                rules={[
                                    { required: true, message: 'Please select a file!' },
                                ]}
                            >
                                <div className='w-full h-60 min-15-15'>
                                    <UploadFiles onFileUploadSuccess={handleFileUploadSuccessMp4} keyPrefix={pathKey}
                                        accept={buildTypeLabel === 'Video' ? "video/*" : 'audio/*'}>
                                        <div className='h-full flex flex-col items-center justify-center'>
                                            <img src={require(`../../../assets/images/public/upload-01.png`)} alt='upload' />
                                            <p className=" text-base font-normal text-[#275576]">Choose {buildTypeLabel} file to upload (mp4)</p>
                                            <p className=' text-base font-normal text-[#959595]'>Or drag and drop it here</p>
                                        </div>
                                        {filePath && buildTypeLabel === 'Video' && <video className='w-full h-full object-cover absolute top-0 left-0' controls>
                                            <source src={filePath} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        }
                                        {filePath && buildTypeLabel === 'Audio' && <audio className='w-full h-full object-cover absolute top-0 left-0' controls>
                                            <source src={filePath} type="video/mp4" />
                                            Your browser does not support the audio tag.
                                        </audio>
                                        }
                                    </UploadFiles>
                                </div>
                            </Form.Item>

                        }
                        {buildTypeLabel === 'Article' &&
                            <Form.Item<FieldType>
                                label="Article content"
                                rules={[
                                    { required: true, message: 'Please select a file!' },
                                ]}
                            >
                                <div className='w-full h-96'>
                                    <WangEditorComponent
                                        keyPrefix={pathKey}
                                        initialValue={initialValues?.description}
                                        onChange={handleEditorChange}
                                        readOnly={false} />
                                </div>
                            </Form.Item>
                        }
                    </div>
                </div>
                <div className='min-14'>
                    <Form.Item<FieldType>
                        name="bannerPath"
                        initialValue={initialValues.bannerPath}
                        rules={[
                            { required: true, message: 'Please select a file!' },
                        ]}
                    >
                        <div className='w-full h-64 min-4 '>
                            <UploadFiles onFileUploadSuccess={handleFileUploadSuccess} keyPrefix={pathKey}>
                                <div className='h-full flex flex-col items-center justify-center'>
                                    <img src={require(`../../../assets/images/public/upload-01.png`)} alt='upload' />
                                    <p className=" text-base font-normal text-[#275576]">Upload image</p>
                                </div>
                                {bannerPath && <img className='w-full h-full object-cover absolute top-0 left-0' src={bannerPath} alt='imgSrc' />}
                            </UploadFiles>
                        </div>
                    </Form.Item>
                    <div className='border-b border-[#D2D2D2] pb-5 min-4'>
                        <p className=' text-xl font-normal text-[#232323]'>Main banner image</p>
                        <div className=' space-y-2 text-lg font-normal text-[#707070]'>
                            <p>Please use JPG or PNG with non-transparent background.</p>
                            <p>Recommended dimensions of 520x240</p>
                        </div>
                    </div>
                    <div className=' py-5 space-y-4 min-4'>
                        <p className=' text-lg font-normal text-[#707070]'>Comments settings</p>
                        <Form.Item<FieldType>
                            name="commentStatus"
                            initialValue={initialValues.commentStatus || 1}
                        >
                            <Radio.Group >
                                <Radio value={1} >Visible</Radio>
                                <Radio value={2}>Hidden</Radio>
                                <Radio value={3}>Locked</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className=' flex items-center space-x-5 min-15'>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='h-11  px-4 space-x-2  flex justify-center items-center min-16'
                        onClick={() => navigate(-1)}
                    >
                        <img src={require('../../../assets/images/public/close.png')} alt='close' />
                        <span className='text-base font-medium text-[#275576] '> Cancel</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2 min-16'
                        htmlType="button"
                        onClick={handlePreview} // 点击时设置为草稿状态
                    >
                        <img src={require('../../../assets/images/public/eye.png')} alt='eye' />
                        <span className=' text-base font-medium text-[#275576] '> Preview</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: 'none', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2 min-16'
                        htmlType="submit"
                        onClick={() => setIsDraft(true)} // 点击时设置为草稿状态
                        loading={loadings}
                    >
                        <img src={require('../../../assets/images/public/save.png')} alt='save' />
                        <span className=' text-base font-medium text-[#275576] '> Save as draft</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='h-11  px-4 flex items-center justify-center space-x-2 min-16'
                        htmlType="submit"
                        onClick={() => setIsDraft(false)}
                        loading={loadings}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send-02' />
                        <span className=' text-base font-medium text-white '> Submit new content</span>
                    </Button>

                </div>
                </div>
            </div>
        </Form>
    )
}
export default Add