import { useRoutes } from "react-router-dom";
import "./App.css";
import "./styles/min-move.css";
import "./styles/new-zp.css";
import routes from "./router";
import { observer } from "mobx-react-lite";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useEffect } from "react";
import { getTokens } from "./api/login";
import { useNavigate } from 'react-router-dom';
import userStore from "./store/user"
// 定义组件
const App = observer(() => {
  const navigate = useNavigate();
  useEffect(() => {
    loginStatus()
  }, [])
  // 获取登陆状态
  const loginStatus = () => {
    const userEamil = localStorage.getItem('USER_EMAIL')
    if (userEamil && userEamil !== '') {
      getBrowserFingerprint().then((fingerprint) => {
        console.log("浏览器指纹:", fingerprint);
        getTokens(fingerprint).then(res => {
          // localStorage.setItem("USER_EMAIL", res.data.token)
            userStore.setUserInfo({userType:res.data.userType})
            userStore.setToken(res.data.token)
            // 跳转到首页
            navigate('/');
        })
        // 走接口
      });
    }
  }
  async function getBrowserFingerprint() {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId; // 返回一个唯一的浏览器指纹
  }

  const outlet = useRoutes(routes);
  return <div className="App">{outlet}</div>;
});

export default App;
