

import { makeAutoObservable, autorun } from 'mobx';
// 详情数据
class DetailsStore {
    Details: any = {}
    open:boolean = true
    constructor() {
        makeAutoObservable(this);
        // 从 sessionStorage 加载数据
        const storedItem = sessionStorage.getItem('Details');
        if (storedItem) {
            this.Details = JSON.parse(storedItem);
        }
        // 监听 item 的变化，当发生变化时保存到 sessionStorage
        autorun(() => {
            sessionStorage.setItem('Details', JSON.stringify(this.Details));
        });
    }

    setDetails(item: any) {
        console.log(item, 888);
        
        this.Details = item;
    }
    clearData() {
        this.Details = {}
    }
    setOpen(i: boolean) {
        if (i) this.open = true
        else this.open = !this.open
        console.log(this.open);
        
    }
}

export default new DetailsStore();
