import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Table, Input, Modal } from 'antd';
import type { TableProps, PopconfirmProps } from 'antd';
import { Button, Popconfirm, message } from 'antd';
import { getMySubmissions } from "../../../api/healthOnline";
import dictStore from '../../../store/dict'
import { useNavigate } from 'react-router-dom';
import type { TablePaginationConfig } from 'antd/lib/table';
import HeadNavigation from '../../../store/HeadNavigation';
import indexStore from '../../../store/index'
import detailsStore from '../../../store/Details'
import { delVideoImgInfo } from '../../../api/healthOnline'

// 类型声明
interface DataType {
    key: string;
    title: string;
    contentTags: string;
    status: string;
    bannerPath: string;
    createTime: string;
}
type StateMap = {
    [key: number]: [string, string]
};
// 我的提交
const MySubmissions = () => {
  const [moduleType] = useState<number>(indexStore.menusState.moduleType); //区别管理员/用户模块
    const [data, setData] = useState<DataType[]>([]); // 存储表格数据
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    // 根据code 获取对应的label值
    const navigate = useNavigate();
    const getLabels = (contentTags: string) => {
        const dictCodes = contentTags.split(',')
        const labels = dictCodes.map(code => {
            const item = dictStore.state.article_tags.find((d: any) => d.dictCode === Number(code)) as any;
            return item ? item.dictLabel : null;
        }).filter(label => label !== null).join(', ');
        return labels
    }

    const urgencyStateName = (state: number) => {
        const stateMap: StateMap = {
            1: ['draft', 'none'],
            2: ['under view', '#D3DDE4'],
            3: ['ready', '#89CFE5'],
            4: ['published', '#EFDA76']
        };

        return stateMap[state] || '';
    }
    // 获取我的提交数据
    const getMySubmissionsFn = async (current: any, size: any,title?:string) => {
        try {
            const res = await getMySubmissions({ current, size,title });
            if (res.data.code === 200) {
                const recordsWithKey = await Promise.all(res.data.data.records.map(async (record: DataType, index: number) => {
                    return {
                        ...record,
                        key: `${index}`, // 为每个记录添加一个唯一的key属性
                        contentTags: getLabels(record.contentTags)
                    };
                }));
                setData(recordsWithKey); // 将获取的数据存储在状态中
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    total: res.data.data.total,
                }));
            }
        } catch (error) {
            console.error("Failed to fetch submissions:", error);
        }
    };

      const edits = (item: any) => {
        detailsStore.setDetails({ ...item })
        navigate(`/eventsActivities/addOrEdit?type=edit`)
      }

          // 删除活动
    const delActivityFn = (item: any) => {
        Modal.confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete it.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await delVideoImgInfo([item.id])
                    if (res.data.code === 200) {
                        message.success('Delete successfully')
                        getMySubmissionsFn(pagination.current, pagination.pageSize);
                        const currentName = moduleType === 1 ? 'Health & wellness' : 'Online classes';
                        HeadNavigation.setItem({ backTo: '/healthOnline/home', goName:currentName, currentName });
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }
    // 表格结构
    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Title',
            dataIndex: 'title',
            render: (_, item) => (
                <div className='flex items-center space-x-7'>
                    <div className='w-24 h-14 overflow-hidden rounded-md bg-[#ccc]'>
                        <img className='w-full h-full' src={item.bannerPath} alt={item.key} />
                    </div>
                    <div className='flex-1 u-line-1 text-xl font-normal text-[#232323] min-19'>{item.title}</div>
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'contentTags',
            ellipsis: true,
            render: (_, item) => (
                <div className='text-xl font-normal text-[#232323] min-19'>{item.contentTags}</div>
            ),
        },
        {
            title: 'Last modified',
            dataIndex: 'createTime',
            ellipsis: true,
            render: (_, item) => (
                <div className='text-xl font-normal text-[#232323] min-19'>{item.createTime}</div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            ellipsis: true,
            // width: 140,
            align: 'center',
            render: (_, item) => (
                <span className={`
                px-5 py-2 text-center  border border-[#DEDEDE] min-19
                 rounded-3xl text-base font-medium text-[#232323]
                `}
                    style={{ background: urgencyStateName(Number(item.status))[1] }}
                >
                    {urgencyStateName(Number(item.status))[0]}
                </span>
            ),
        },
        {
            title: 'operate',
            dataIndex: 'operate',
            ellipsis: true,
            fixed: 'right',
            width: 180,
            align: 'center',
            render: (_, item) => (
                <>
                    <Button style={{color: '#335473'}} type="text" onClick={() => edits(item)}>
                            deit
                    </Button>
                    <Button style={{color: 'red'}} type="text" onClick={() => delActivityFn(item)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];
    // 点击分页
    const handleTableChange = (newPagination: TablePaginationConfig) => {
        setPagination(newPagination);
        getMySubmissionsFn(newPagination.current, newPagination.pageSize);
    };
    useEffect(() => {
        getMySubmissionsFn(pagination.current, pagination.pageSize);
        const currentName = moduleType === 1 ? 'Health & wellness' : 'Online classes';
        HeadNavigation.setItem({ backTo: '/healthOnline/home', goName:currentName, currentName });
    }, []);
    //根据标题搜索
    const [isModalOpen, setIsModalOpen] = useState(false);
    const searchStrFn=()=>{
        getMySubmissionsFn(pagination.current, pagination.pageSize,searchValue);
    }
    // 显示模态框
    const showModal = () => {
        setIsModalOpen(true);
    };
    // 关闭模态框
    const handleCancel = () => {
        setIsModalOpen(false);
    };
     // 根据导航类型跳转
     const navigationTypeJump = (dictCode: number) => {
        navigate(`/healthOnline/addOrEdit?type=add&buildType=${dictCode}`);
    }
    return (
        <div className="py-5 px-14 space-y-7 min-6">
            <div className="flex items-center justify-between">
                <div className="text-4xl font-medium text-[#232323] min-10 min-25">My submission</div>
                <Input
                    className="w-64 h-12 rounded-full bg-[#F1F1F1]"
                    placeholder="Search"
                    suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }}  />}
             
                    onPressEnter={searchStrFn}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                
                />
                <Button shape="round"
                    style={{ background: '#275576', borderColor: '#275576', width: '250px' }}
                    className='w-full h-11 flex items-center justify-center space-x-2 min-4'
                    onClick={showModal}>
                    <img src={require('../../../assets/images/public/send-01.png')} alt='send' />
                    <span className='text-base font-medium text-white'>Submit new content</span>
                </Button>
            </div>
            <div className="min-15">
            <Button shape="round"
                style={{ background: '#275576', borderColor: '#275576' }}
                className='w-full h-11 flex items-center justify-center space-x-2'
                onClick={showModal}>
                <img src={require('../../../assets/images/public/send-01.png')} alt='send' />
                <span className='text-base font-medium text-white'>Submit new content</span>
            </Button>
            </div>
            <div className="border border-[#D2D2D2] rounded-md">
                <Table
                    className="custom-table"
                    columns={columns}
                    dataSource={data}
                    scroll={{ y: 550, x: 'max-content' }}
                    pagination={pagination}
                    onChange={handleTableChange}
                />
            </div>
            <Modal title="Which type of content you are going to submit?" centered open={isModalOpen}
                footer={null}
                width={700}
            >
                <div className=' grid grid-cols-3 justify-between my-7'>
                    {dictStore.state.article_type.map((tag_item: any, tag_index) => (
                        <div key={tag_index} className='flex flex-col items-center py-5  space-y-3 cursor-pointer'
                            onClick={() => navigationTypeJump(tag_item.dictCode)}
                            style={tag_index === 1 ? { borderRight: '1px solid #D2D2D2', borderLeft: '1px solid #D2D2D2' } : {}}>
                            <img className=' w-8 h-8' src={require(`../../../assets/images/healthWellness/${tag_item.dictLabel}.svg`)} alt={`${tag_item.dictLabel}`} />
                            <span className=' text-2xl font-normal text-[#232323]'>{tag_item.dictLabel}</span>
                        </div>
                    ))}
                </div>
                <Button shape="round"
                    style={{ background: '#E9EEF1' }}
                    className='w-full h-11'
                    onClick={handleCancel} >
                    <span className=' text-base font-medium text-[#275576] '>Cancel</span>
                </Button>
            </Modal>
        </div>
    );
};

export default MySubmissions;
