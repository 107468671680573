import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dictStore from '../../store/dict';
import { delActivity } from '../../api/activity'
import detailsStore from '../../store/Details'
import userStore from '../../store/user'

import dayjs from 'dayjs'
// 定义类型
interface CardItemProps {
    item: any;
    onUpdate: () => void;
}
// 卡片组件
const CardItem: React.FC<CardItemProps> = ({ item, onUpdate }) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [isShowMore, setIsShowMore] = useState<boolean>(false);
    const userInfo = userStore.state.userInfo//用户信息
    // 跳转
    const navigationTypeJump = () => {
        navigate(`/eventsActivities/details?id=${item.id}`);
    }
    // 通过dict获取标签的函数
    const getDictLabel = (type: number) => {
        const dictItem = dictStore.state.activity_category.find((item: any) => item.dictCode === type) || { dictLabel: '' };
        return dictItem.dictLabel;
    }
    // 通过dict获取标签的函数
    const getActivityTypeDictLabel = (type: number) => {
        const dictItem = dictStore.state.activity_type.find((item: any) => item.dictCode === type) || { dictLabel: '' };
        return dictItem.dictLabel;
    }
    // 删除活动
    const delActivityFn = (id: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        Modal.confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete it.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await delActivity([id])
                    if (res.data.code === 200) {
                        message.success(res.data.msg);
                        // 通知父组件更新数据
                        onUpdate();
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }
    // 编辑活动
    const editActivityFn = (item: any, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        detailsStore.setDetails({ ...item })
        navigate(`/eventsActivities/addOrEdit?type=edit`)
    }
    // 是否显示更多
    const setIsShowMoreHandle = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        setIsShowMore(!isShowMore)
    }
    return (
        <div className=' p-5 border border-[#D2D2D2] rounded-xl space-y-4 cursor-pointer'
            onClick={navigationTypeJump}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className='flex-1'>
                <p className=' text-2xl font-medium text-[#232323] min-25'>{item.title}</p>
                <p className=' text-xl font-normal text-[#707070] u-line-1 min-25'>
                    {dayjs(item.startTime).format('dddd, MMMM D, YYYY h:mm A')}
                </p>
            </div>
            <div className=' relative pb-[13.875rem] bg-[#CCCCCC] rounded-md overflow-hidden'>
                <img className=' w-full h-full object-cover absolute top-0 left-0 rounded-md'
                    src={item.bannerPath}
                    alt={`${item.id}`} />
                {isHovered && (userInfo.userType === '00' || Number(userInfo.id) === Number(item.createBy)) &&
                    <div className='absolute top-2 left-2 flex space-x-1'>
                        <div className={`w-10 h-10  flex justify-center z-10 cursor-pointer rounded-full
                           bg-white bg-opacity-10 text-2xl text-[#275576] backdrop-blur-[6.099999904632568px]`}
                            onClick={(e) => editActivityFn(item, e)}>
                            <EditOutlined />
                        </div>
                        <div className={`w-10 h-10  flex justify-center z-10 cursor-pointer rounded-full
                           bg-white bg-opacity-10 text-2xl text-[#EA5E5E] backdrop-blur-[6.099999904632568px]`}
                            onClick={(e) => delActivityFn(item.id, e)}>
                            <DeleteOutlined />
                        </div>
                    </div>
                }
                <span className=" absolute z-10 bottom-2 left-2 bg-white rounded-2xl py-2 px-3 w">
                    {getDictLabel(item.category)}
                </span>
                <div className="w-10 h-10 absolute bottom-2 right-2 flex items-center justify-center z-10 rounded-full
                             bg-white bg-opacity-10"
                    style={{ backdropFilter: 'blur(6.099999904632568px)' }}>
                    <img src={require(`../../assets/images/public/${item.commentStatus === 1 ? 'globe' : 'lock'}.png`)}
                        className=' w-5 h-5' alt='globe' />
                </div>
            </div>
            <div className="flex items-start justify-between space-x-1">
                <div className={`flex-1 text-2xl font-medium text-[#232323] u-line-${!isShowMore ? 1 : 3}`}>
                    {item.description}
                </div>
                {item.description && item.description.length > 30 && <div className='underline underline-offset-2 text-xl font-normal text-[#959595] cursor-pointer'
                    onClick={(e) => setIsShowMoreHandle(e)}>
                    {!isShowMore ? 'Read More' : 'Hide'}
                </div>}
            </div>
            <div className="flex space-x-2 text-xl font-normal text-[#707070] ">
                <span>Attendees {item.pcount}</span>
                {item.pcount - item.attendCount > 0 && item.pcount - item.attendCount !== item.pcount && (
                    <div className=' space-x-1'>
                        <span> – </span>
                        <span className='text-[#EA5E5E]'>{item.pcount - item.attendCount} spots left</span>
                    </div>
                )}
            </div>
            <div className='flex items-center space-x-2'>
                <span className=' text-xl font-normal text-[#275576]'>{getActivityTypeDictLabel(item.type)}</span>
            </div>
        </div>
    )
}
export default CardItem