import { Input, Button, Form, Select, message } from "antd";
import type { FormProps } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getListClassification } from "../../../api/gaming";
import UploadFiles from "../../../components/Public/UploadFiles";
import { addGame, updateGame } from "../../../api/gaming";
import detailsStore from "../../../store/Details";
import HeadNavigation from "../../../store/HeadNavigation";
type FieldType = {
  id: number;
  title: string;
  description: string;
  content: string;
  classificationId: number;
  participateInCount: number;
  filePath: string;
};

// 上传文件
const Add = () => {
  // 定义
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  // 根据页面状态（添加或编辑）初始化文件路径和表单初始值
  const [classificationData, setClassificationData] = useState<any[]>([]);
  const [initialValues] = useState<any>(
    type === "add" ? {} : detailsStore.Details
  ); //详情 初始表单
  const [filePath, setFilePath] = useState<string | null>(
    initialValues?.filePath || ""
  ); //文件封面
  // 加载状态
  const [loadings, setLoadings] = useState<boolean>(false);
  // 表单提交处理
  const handleSignupClick: FormProps<FieldType>["onFinish"] = async (
    values
  ) => {
    setLoadings(true);
    const param: any = {
      ...values,
      filePath: filePath,
    };
    try {
      const res =
        type === "add"
          ? await addGame(param)
          : await updateGame({ ...param, id: searchParams.get("id") });

      if (res.data.code === 200) {
        message.success(res.data.msg);
        navigate("/gaming/home");
      }
      setLoadings(false);
    } catch (error) {
      setLoadings(false);
      console.error("Error submitting form:", error);
    }
  };

  // 获取游戏类型
  const getListClassificationFn = async () => {
    try {
      const res = await getListClassification();
      setClassificationData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 文件上传成功并获取到 url
  const handleFileUploadSuccess = (url: string) => {
    setFilePath(url);
  };
  // 在组件挂载后调用
  useEffect(() => {
    getListClassificationFn();
    HeadNavigation.setItem({
      backTo: "/gaming/home",
      goName: "Games",
      currentName: "Games",
    });
  }, []);
  return (
    <Form
      style={{ maxWidth: "100%" }}
      initialValues={{ remember: true, ...initialValues }}
      onFinish={handleSignupClick}
      layout="vertical"
      className="w-full h-full flex flex-col py-5 px-14 space-y-7  min-1-1"
    >
      <div className="flex items-center justify-between min-1-1">
        <div className="text-4xl font-medium text-[#232323] min-10">
          Submit new Gaming content
        </div>
        <div className=" flex items-center space-x-5 min-4">
          <Button
            shape="round"
            style={{ background: "#275576" }}
            className="h-11  px-4 flex items-center justify-center space-x-2 "
            htmlType="submit"
            loading={loadings}
          >
            <img
              src={require("../../../assets/images/public/send-01.png")}
              alt="send-02"
            />
            <span className=" text-base font-medium text-[#FFF] ">
              {" "}
              Submit new content
            </span>
          </Button>
        </div>
      </div>
      <Form.Item<FieldType>
          name="filePath"
          label=" "
          rules={[{ required: true, message: "Please select a file!" }]}
        >
          <div className="w-full h-44">
            <UploadFiles
              onFileUploadSuccess={handleFileUploadSuccess}
              keyPrefix="gaming"
            >
              <div className="h-full flex flex-col items-center justify-center ">
                <img
                  src={require(`../../../assets/images/public/upload-01.png`)}
                  alt="upload"
                />
                <p className=" text-base font-normal text-[#275576]">
                  Upload image
                </p>
              </div>
              {filePath && (
                <img
                  className="w-full h-full object-cover absolute top-0 left-0"
                  src={filePath}
                  alt="imgSrc"
                />
              )}
            </UploadFiles>
          </div>
          <div className="space-y-2 px-4 min-15" style={{marginTop: '20px'}}>
                <p className=" text-xl font-normal text-[#232323]">
                  Main banner image
                </p>
                <div className=" space-y-2 text-lg font-normal text-[#707070]">
                  <p>Please use JPG or PNG with non-transparent background.</p>
                  <p>Recommended dimensions of 520x240</p>
                </div>
              </div>
        </Form.Item>
      <div className="flex-1 h-full border border-[#D2D2D2] rounded-md overflow-hidden min-1-1">
        <p className="w-full px-5 text-xl font-medium h-16 leading-[4rem] bg-[#F6F8F8]">
          Details
        </p>
        <div className="p-5 space-y-5">
          <div className="w-full flex justify-between space-x-5 min-18">
            <Form.Item<FieldType>
              className="w-full h-14 min-17"
              name="title"
              label="Title"
              rules={[
                { required: true, message: "Please input Ex: Power of light." },
              ]}
            >
              <Input
                placeholder="Ex: Power of light."
                style={{ height: "56px", width: "100%" }}
              />
            </Form.Item>
            <Form.Item<FieldType>
              className="w-full h-14 min-17"
              name="classificationId"
              label="Tags"
              rules={[{ required: true, message: "Please Select a type " }]}
            >
              <Select
                style={{ height: "56px", width: "100%" }}
                placeholder="Select a type"
              >
                {classificationData.map((item) => (
                  <Select.Option
                    label={item.classification}
                    value={item.id}
                    key={item.id}
                  >
                    {item.classification}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="w-full flex justify-between space-x-5 min-18">
            <div className=" flex flex-col space-y-4">
              <Form.Item<FieldType>
                className="w-full h-14 min-17"
                name="content"
                label="Link"
              >
                <Input
                  placeholder="Please input Link."
                  style={{ height: "56px", width: "100%" }}
                />
              </Form.Item>
              <Form.Item<FieldType>
                name="description"
                label="Summary"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email address or phone number!",
                  },
                ]}
                className="w-full min-17-17-17"
              >
                <Input.TextArea style={{height: '120px'}} rows={9} placeholder="Write here.." />
              </Form.Item>
            </div>
            <div className="min-14">
              <Form.Item<FieldType>
                className="min-4"
                name="filePath"
                label=" "
                rules={[{ required: true, message: "Please select a file!" }]}
              >
                <div className="w-full h-64">
                  <UploadFiles
                    onFileUploadSuccess={handleFileUploadSuccess}
                    keyPrefix="gaming"
                  >
                    <div className="h-full flex flex-col items-center justify-center ">
                      <img
                        src={require(`../../../assets/images/public/upload-01.png`)}
                        alt="upload"
                      />
                      <p className=" text-base font-normal text-[#275576]">
                        Upload image
                      </p>
                    </div>
                    {filePath && (
                      <img
                        className="w-full h-full object-cover absolute top-0 left-0"
                        src={filePath}
                        alt="imgSrc"
                      />
                    )}
                  </UploadFiles>
                </div>
              </Form.Item>

              <div className=" flex items-center space-x-5 min-15">
                <Button
                  shape="round"
                  style={{ background: "#275576" }}
                  className="h-11  px-4 flex items-center justify-center space-x-2 min-16"
                  htmlType="submit"
                  loading={loadings}
                >
                  <img
                    src={require("../../../assets/images/public/send-01.png")}
                    alt="send-02"
                  />
                  <span className=" text-base font-medium text-[#FFF] ">
                    {" "}
                    Submit new content
                  </span>
                </Button>
              </div>

              <div className="space-y-2 px-4 min-4">
                <p className=" text-xl font-normal text-[#232323]">
                  Main banner image
                </p>
                <div className=" space-y-2 text-lg font-normal text-[#707070]">
                  <p>Please use JPG or PNG with non-transparent background.</p>
                  <p>Recommended dimensions of 520x240</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default Add;
