import request from "../composables/request";
/**
 * 登录邮箱获取验证码
 * @param username  用户名
 * @returns 
 */
export const getCode = (params: { username: string,type:string }) => {
  return  request.get('/getCode', { params });
}
/**
 * 登录获取token
 * @param username 用户名
 * @param uuid id
 * @param code  验证码
 * @returns 
 */
export const loginByEmail = (params: {
    username: string,
    uuid: string,
    code: string
}) => {
    return request.post('/loginByEmail', { ...params })
}
export const setTokens = (data: {
  macValue: string,
  tokenValue: string,
}) => {
    return request.post('/api/personal/macToken/private/saveOrUpdate', { ...data })
}
export const getTokens = (macValue: string) => {
    return request.get('/api/personal/macToken/public/getTokenByMac?macValue='+macValue)
}
