import request from "../composables/request";
/**
 * 查看视频音频文章
 * @param current 当前页 
 * @param size 页大小
 * @param category 类别
 * @param moduleType 模块类型
 * @param type 类型
 * @param subTitle 小标题
 * @param subTitle 大标题
 * @returns 
 */
export const getPageVideoImgInfo = (params: {
  current?: number,
  size?: number,
  category?: number | string | null,
  moduleType?: number,
  type?: number | null,
  subTitle?: string,
  title?: string,
}) => {
  return request.get('/api/personal/video/public/pageVideoImgInfo', { params });
}
/**
 * 查看我的收藏夹
 * @param current 当前页 
 * @param size 页大小
 * @param type 类型
 * @param favorites 收藏夹类型（字典中获取）
 * @param subTitle 小标题
 * @returns 
 */
export const getViewFavorites = (params: {
  current?: number,
  size?: number,
  type?: number | null,
  favorites?: number | null,
  subtitle?: string,
}) => {
  return request.get('/api/personal/video/priavte/viewFavorites', { params });
}
/**
 * 添加到收藏夹
 * @param articleId 文章的id 
 * @param type 文章类型
 * @param favorites 收藏夹类型
 * @returns 
 */
export const addFavorites = (params: {
  articleId: string,
  type: number | null,
  favorites?: number | null,
}) => {
  return request.put('/api/personal/video/private/addFavorites', params);
}
/**
 * 添加视频音频文章信息
 * @param title 标题
 * @param subtitle 小标题
 * @param category 分类（字典：article_category ）左侧分类
 * @param moduleType 模块分类（模块类型（1：Health 2：online））
 * @param description 描述
 * @param type 类型（字典：article_type）音频，视频，文章分类
 * @param filePath 文件地址
 * @param bannerPath 横幅地址
 * @param status 状态（1：draft，2：under view, 3,：ready，4：published)
 * @param commentStatus 评论状态（1:显示评论，2：隐藏评论，3：锁定评论）
 * @param contentTags 标签（标签1;标签2）
 * @returns 
 */
export const addVideoImgInfo = (params: {
  title: string,
  subtitle: string,
  category: number,
  moduleType: number,
  description: number,
  type: number,
  filePath: string,
  bannerPath: string,
  status: number,
  commentStatus:number,
  contentTags: string,
  
}) => {
  return request.post('/api/personal/video/private/addVideoImgInfo', params);
}
/**
 * 编辑视频音频文章信息
 * @param title 标题
 * @param subtitle 小标题
 * @param category 分类（字典：article_category ）左侧分类
 * @param moduleType 模块分类（模块类型（1：Health 2：online））
 * @param description 描述
 * @param type 类型（字典：article_type）音频，视频，文章分类
 * @param filePath 文件地址
 * @param bannerPath 横幅地址
 * @param status 状态（1：draft，2：under view, 3,：ready，4：published)
 * @param commentStatus 评论状态（1:显示评论，2：隐藏评论，3：锁定评论）
 * @param contentTags 标签（标签1;标签2）
 * @returns 
 */
export const updateVideoImgInfo = (params: {
  title: string,
  subtitle: string,
  category: number,
  moduleType: number,
  description: number,
  type: number,
  filePath: string,
  bannerPath: string,
  status: number,
  commentStatus:number,
  contentTags: string,
}) => {
  return request.put('/api/personal/video/private/updateVideoImgInfo', params);
}

/**
 * 删除视频音频信息
 * @param arr 
 * @returns 
 */
export const delVideoImgInfo = (arr: any) => {
  return request.delete(`/api/personal/video/private/delVideoImgInfo`, { data: arr });
}
/**
* 查看评论（时间排序）
* @param current 
* @param size 
* @param moduleType 模块分类（模块类型（1：Health 2：online））
* @param articleId 文章id
* @returns 
*/
export const getViewComment = (params: {
  current?: number,
  size?: number,
  moduleType: number,
  articleId: number,
}) => {
  return request.get('/api/personal/video/public/viewComment', { params });
}
/**
 * 添加评论
 * @param content 评论内容
 * @param score 评论打分
 * @param moduleType 模块分类（模块类型（1：Health 2：online））
 * @param articleId 文章id
 * @returns 
 */
export const addComment = (params: {
  content: string,
  score: number,
  moduleType: number,
  articleId: number,
}) => {
  return request.post('/api/personal/video/private/addComment', params);
}
/**
 * 我的提交
 * @param current 当前页
 * @param size 页大小
 * @param status 状态（1：显示，2：隐藏，3：锁定，4草稿）
 * @param title 按标题搜索
 * @returns 
 */
export const getMySubmissions = (params: {
  current?: number,
  size?: number,
  status?: number,
  title?:string
}) => {
  return request.get('/api/personal/video/private/mySubmissions', { params });
}
/**
 * 添加观看量
 * @param id 添加观看量的视频或音频
 * @returns 
 */
export const countViewingQuantity = (id: number) => {
  return request.post(`/api/personal/video/public/countViewingQuantity?id=${id}`);
}
/**
 * 评论点赞/取消点赞
 * @param commentId 评论ID
 * @returns 
 */
export const videoCommentStar = (commentId: number | string) => {
  return request.put('/api/personal/video/private/commentStar', { commentId });
}


/**
 * 点赞/取消点赞
 * @param commentId 评论ID
 * @returns 
 */
export const videoLike = (id: number | string) => {
  return request.get('/api/personal/video/private/giveTheThumbsUp?id='+id);
}

/**
 * 根据ID获取视频文章详情
 * @param id 
 * @returns 
 */
export const getVideoDetails = (id: number) => {
  return request.get(`/api/personal/video/public/videoInfo?id=${id}`);
}
